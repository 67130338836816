export const loadLib=(url, idTag)=>{
    return new Promise((resolve, reject) => {
      globalThis.idTagDelio = idTag;
      const script = globalThis.document.createElement("script");
      script.src = url;
      script.id = "initWalmeric";
      script.async = true;
      script.onload = () => {
        let timeKill=setTimeout(()=>{
            clearTimeout(timeKill)
            resolve(true); // Carga
          },1200)
    };
      script.onerror = reject; // No carga
      document.head.appendChild(script);
    });
}
  