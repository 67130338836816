/**
 * Variables Globales para Movistar Prosegur
 */

export const Globals = {
  /*Id Tag D1C*/
  idTagD1C:
    "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a79f801189427e6fd80bded7b57626086a379186e8ca5be248955977244053d3ed28b60be10a6b89e445b367be39de2cdf300b5e93295c90044d341367fd9adbe3f666296a8072a823a9f6fc80d821316",
  updateVisitLabels:
    "https://ws.walmeric.com/provision/visitsLabel/updateVisitLabelByVisitorId.html",
  DelioOneClick: {
    init: "https://t.womtp.com/slider/c/delio-lm/dfi/init.js",
  },
};
