import { DelioLabels } from "./class/DelioLabels";
import { Globals } from "./globals/variables";




(async () => {
  const delioLabels = new DelioLabels();
  globalThis.digitalData = delioLabels.getDigitalData();
  delioLabels.loadDelioLibs(Globals.DelioOneClick.init, Globals.idTagD1C);
 
})();
