import { getUtmVisit } from "../utils/setVisitDigital";
import { loadLib } from "../utils/utils";

export class DelioLabels {
  constructor() {
    // Combina las propiedades del objeto global digitalData con un objeto vacío inicial
    this.digitalData = { 
      ...globalThis.digitalData 
    };

    
  }

  // Método privado para combinar datos en digitalData
  #setDigitalData() {
    const declaratives = DFI.labels?.EDeclaratives || {};
    const visits = DFI.labels?.EVisits || {};

    const visitData=getUtmVisit().etiquetas;

    const data = {
        ...visitData,
        
    };

    // Combina EDeclaratives, EVisits y los datos adicionales en digitalData
    this.digitalData = {
      ...declaratives,    // Propiedades de DFI.labels.EDeclaratives (o un objeto vacío si no está definido)
      ...visits,          // Propiedades de DFI.labels.EVisits (o un objeto vacío si no está definido)
      ...data             // Datos adicionales
    };

    globalThis.digitalData = this.digitalData; // Actualiza el objeto global
  }

  /**
   * Devuelve el objeto digitalData
   * @returns {Object} digitalData
   */
  getDigitalData() {
    return this.digitalData;
  }

  // Método para cargar librerías y gestionar el resultado
  async loadDelioLibs(url, idTag) {
    try {
      const result = await loadLib(url, idTag);
      if (result) {
        this.#setDigitalData(); 
        console.log("Script cargado correctamente: " + result);
      }
    } catch (error) {
      console.error("Error al cargar el script:", error);
    }
  }

   

}