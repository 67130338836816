const getUrlVar = function(variable, url) {
    var href = url || window.location.search;
    var i, pair;
    var query = href.split("?")[1];
    var vars = query ? query.split("&") : [];

    for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split("=");
        // Comprobar si el nombre de la variable coincide y si el valor no es undefined o null
        if (pair[0] === variable) {
            return pair[1] ? decodeURIComponent(pair[1]) : ""; // Devuelve el valor decodificado o vacío
        }
    }

    return ""; // Si no se encuentra la variable, devuelve vacío
};
export const getUtmVisit = function () {
    const visitLabelsUtms = {
        etiquetas: {} // Objeto para almacenar las etiquetas
    };

    // Inicializar cookies si no están definidas
    if (typeof digitalData["cookies_accepted_analisis"] === 'undefined' && typeof digitalData["cookies_accepted_publicidad"] === 'undefined') {
        digitalData["cookies_accepted_analisis"] = "false";
        digitalData["cookies_accepted_publicidad"] = "false";
    }
    
    // Almacenar el user_agent codificado
    visitLabelsUtms.user_agent = encodeURIComponent(navigator.userAgent);

    var medios = ['sem', 'dis', 'sop', 'eml', 'afl', 'agg', 'int', 'bcb', 'wpu', 'ome', 'app', 'vau'];
    
    medios.forEach(function (medio) {
        var etiquetas = getUrlVar(medio); // Usar la función para obtener el valor
        if (etiquetas) {
            etiquetas.split(':').forEach(function (valor, i) {
                if (valor) {
                    var keys = ['Fuente de Tráfico', 'Proveedor', 'Campaña', 'visit_46'];
                    if (i < keys.length) {
                        visitLabelsUtms.etiquetas[keys[i]] = valor; // Almacenar en visitLabelsUtms.etiquetas
                    } else {
                        visitLabelsUtms.etiquetas['visit_' + (i - keys.length + 20)] = valor; // Almacenar valores adicionales
                    }
                }
            });
            visitLabelsUtms.etiquetas['visit_42'] = medio; // Almacenar el medio en el objeto etiquetas
        }
    });
    console.log(visitLabelsUtms)
    return visitLabelsUtms; // Muestra el objeto visitLabelsUtms
    
};  